// Font-Sizes:
$font-h1: 3.2rem;
$font-h2: 2.8rem;
$font-h3: 2rem;
$font-text: 1.8rem;

// Colors
$color-black: #000;
$color-white: #fff;
$color-blue-3: #74c0fc;
$color-green-3: #8ce99a;
$color-teal-3: #63e6be;
$color-red-3: #ffa8a8;