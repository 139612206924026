.budget {
    max-width: 128rem;
    margin: 0 auto;

    // Total budget
    .total-budget {
        background-color: $color-teal-3;
        margin-bottom: 2rem;
        padding: 2rem;
        text-align: center;

        &__total {
            font-weight: 400;
        }
    }

    // Manage budget
    .manage-budget {
        display: flex;
        gap: 2rem;

        &--income { background-color: $color-green-3; }
        &--expense { background-color: $color-red-3; }
    }

    // Income & expense budget
    .income-budget,
    .expense-budget {
        flex: 1;
        padding: 2rem;

        &__title {
            font-size: $font-h2;
            margin-bottom: 2rem;
            text-align: center;
        }

        &__total {
            font-weight: 400;
        }

        // Add income & expense budget
        .add-income-budget,
        .add-expense-budget {
            &__title {
                margin-bottom: 1rem;
            }
            
            &-form {
                &__row {
                    display: flex;
                    gap: 2rem;
                    margin-bottom: 2rem;
                }

                &__group {
                    flex: 1;
                    display: block;
                    width: 100%;
                }

                &__label,
                &__input {
                    display: block;
                    width: 100%;
                }

                &__label {
                    margin-bottom: .5rem;
                }

                &__input {
                    background-color: rgba($color-white, .3);
                    border: none;
                    color: $color-black;
                    font-size: $font-text;
                    padding: 1rem 1.5rem;
                    outline: none;

                    &::-webkit-input-placeholder {
                        color: $color-black;
                    }
                }

                &__button {
                    background-color: $color-black;
                    border: none;
                    color: $color-white;
                    cursor: pointer;
                    font-size: $font-h3;
                    display: block;
                    outline: none;
                    padding: 1rem;
                    text-align: center;
                    width: 100%;
                }
            }
        }

        // Listing incomes & expenses
        .listing-incomes,
        .listing-expenses {
            margin-top: 2rem;

            &__title {
                margin-bottom: 1rem;
            }

            &-list {
                list-style: none;
                margin: 0;
                padding: 0;

                &__item {
                    background-color: rgba($color-white, .3);
                    display: flex;
                    justify-content: space-between;
                    padding: 1.5rem;
                    transition: all .3s ease-in-out;

                    &:not(:last-child) {
                        margin-bottom: 2rem;
                    }

                    &:hover .listing-incomes-list__erase-button,
                    &:hover .listing-expenses-list__erase-button {
                        opacity: 1;
                    }
                }

                &__percentage {
                    font-weight: 700;
                }

                &__erase-button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    font-size: $font-text;
                    margin-left: 1rem;
                    opacity: 0;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
}